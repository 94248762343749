import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isAuthPro:false,
        theUserProID:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
    isLoggedIn = async () => {
        let loginID = JSON.parse(localStorage.getItem('loginID'));
        if (localStorage.getItem('loginID')) {
            this.setState({
                ...this.state,
                isAuthPro:true,
                theUserProID:loginID,
            });
        }
    }
    logoutUser = () => {
        localStorage.removeItem('loginID');
        this.setState({
            ...this.state,
            isAuthPro:false,
            theUserProID:null,
        })
        window.location.href = '/LoginSection';
    }
    RegisterUser = async (userInfo,recaptcha_response) => {
        const registerqqq = await Axios.post('/login/register.php',{
            user_name:userInfo.name,
            user_email:userInfo.email,
            password:userInfo.password,
            subscribe:userInfo.subscribe,
            authortype:userInfo.authortype,
            recaptcha_response:recaptcha_response,
        });
        return registerqqq.data;
    }
    LoginUser = async (user) => {
        const LoginUserqqq = await Axios.post('/login/login.php',{
            email:user.email,
            password:user.password,
        });
        return LoginUserqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('/login/forgetpassword.php',{
            email:user.email,
        });
        return loginForget.data;
    }
    ChangePasswordall = async (user,passkey) => {
        const ChangePasswordallsss = await Axios.post('/login/changePasswordall.php',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return ChangePasswordallsss.data;
    }
    userdetailsall = async (userid) => {
        const userdetailsallsss = await Axios.post('userdetailsall.php',{
            userids:userid,
        });
        return userdetailsallsss.data;
    }
    editprofileall = async (books,userids) => {
        const formData = new FormData();
        formData.append('name',books.name);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('facebook',books.facebook);
        formData.append('twitter',books.twitter);
        formData.append('linkedin',books.linkedin);
        formData.append('instagram',books.instagram);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('publisherstitle',books.publisherstitle);
        formData.append('userids',userids);
        const editprofileallsss = await Axios.post('editprofile.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editprofileallsss.data;
    }
    
    Booksaddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('paperback',books.amazonpaperback);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('abouts',books.abouts);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        formData.append('theUserid',theUserid);
        const Booksaddallqqq = await Axios.post('booksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksaddallqqq.data;
    }
    Booksaddalltwo = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('paperback',books.amazonpaperback);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('abouts',books.abouts);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        formData.append('theUserid',theUserid);
        const Booksaddalltwoqqq = await Axios.post('booksaddtwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksaddalltwoqqq.data;
    }
   
    userbooksall = async (userid) => {
        const userbooksallsss = await Axios.post('userbooksall.php',{
            userids:userid,
        });
        return userbooksallsss.data;
    }
    bookgetidall = async (ids) => {
        const bookgetidallqqq = await Axios.post('bookeditall.php',{
            ids:ids,
        });
        return bookgetidallqqq.data;
    }
    editbookall = async (books,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('paperback',books.amazonpaperback);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('abouts',books.abouts);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        formData.append('bookids',bookids);
        const editbookallqqq = await Axios.post('booksupdate.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editbookallqqq.data;
    }
    AudioBooksaddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        formData.append('theUserid',theUserid);
        const AudioBooksaddallqqq = await Axios.post('audiobooksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBooksaddallqqq.data;
    }
    AudioBooksaddalltwo = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        formData.append('theUserid',theUserid);
        const AudioBooksaddalltwoqqq = await Axios.post('audiobooksaddtwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBooksaddalltwoqqq.data;
    }
    editaudiobookall = async (books,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',books.category);
        formData.append('descothers',books.descothers);
        formData.append('price',books.price);
        formData.append('authortype',books.authortype);
        formData.append('authortypename',books.authortypename);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('bookids',bookids);
        formData.append('amazon',books.amazon);
        formData.append('barnes',books.barnes);
        formData.append('apple',books.apple);
        formData.append('google',books.google);
        formData.append('kobo',books.kobo);
        formData.append('purchase',books.purchase);
        formData.append('videotitle',books.videotitle);
        formData.append('videourl',books.videourl);
        formData.append('genres',books.genres);
        const editaudiobookallqqq = await Axios.post('audiobooksupdate.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editaudiobookallqqq.data;
    }
    booktrailersall = async (userid) => {
        const booktrailersallsss = await Axios.post('booktrailersall.php',{
            userids:userid,
        });
        return booktrailersallsss.data;
    }
    Booktraileraddall = async (books,userid) => {
        const Booktraileraddallsss = await Axios.post('booktrailersadd.php',{
            title: books.title,
            url: books.url,
            abouts: books.abouts,
            amazon: books.amazon,
            barnes: books.barnes,
            apple: books.apple,
            google: books.google,
            kobo: books.kobo,
            purchase: books.purchase,
            userids:userid,
        });
        return Booktraileraddallsss.data;
    }
    booktrailergetidall = async (ids) => {
        const booktrailergetidallsss = await Axios.post('edittrailerlist.php',{
            ids:ids,
        });
        return booktrailergetidallsss.data;
    }
    Booktrailereditall = async (books,videoid) => {
        const Booktrailereditallsss = await Axios.post('booktrailersedit.php',{
            title: books.title,
            url: books.url,
            abouts: books.abouts,
            amazon: books.amazon,
            barnes: books.barnes,
            apple: books.apple,
            google: books.google,
            kobo: books.kobo,
            purchase: books.purchase,
            videoid:videoid,
        });
        return Booktrailereditallsss.data;
    }
    bookpaysmsg = async (payids) => {
        const bookpaysmsgqqq = await Axios.post('stripesuccess.php',{
            payids:payids,
        });
        return bookpaysmsgqqq.data;
    }
    bookpaypalmsg = async (payids) => {
        const bookpaypalmsgqqq = await Axios.post('paypalsuccess.php',{
            payids:payids,
        });
        return bookpaypalmsgqqq.data;
    }
    userpublishersall = async (userid) => {
        const userpublishersallsss = await Axios.post('userpublishersall.php',{
            userids:userid,
        });
        return userpublishersallsss.data;
    }
    userpoemall = async (userid) => {
        const userpoemallsss = await Axios.post('userpoemall.php',{
            userids:userid,
        });
        return userpoemallsss.data;
    }
    userarticleall = async (userid) => {
        const userarticleallsss = await Axios.post('userarticleall.php',{
            userids:userid,
        });
        return userarticleallsss.data;
    }
    publisheraddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const publisheraddallsss = await Axios.post('publisheradd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return publisheraddallsss.data;
    }
    articleaddall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const articleaddallsss = await Axios.post('articleadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return articleaddallsss.data;
    }
    poemaddall = async (books,theUserid) => {
        const poemaddallsss = await Axios.post('poemadd.php',{
            title:books.title,
            abouts:books.abouts,
            theUserid:theUserid,
        });
        return poemaddallsss.data;
    }
    pubsgetidall = async (ids) => {
        const pubsgetidallsss = await Axios.post('pubsgetidall.php',{
            ids:ids,
        });
        return pubsgetidallsss.data;
    }
    poemgetidall = async (ids) => {
        const poemgetidallsss = await Axios.post('poemgetidall.php',{
            ids:ids,
        });
        return poemgetidallsss.data;
    }
    articlegetidall = async (ids) => {
        const articlegetidallsss = await Axios.post('articlegetidall.php',{
            ids:ids,
        });
        return articlegetidallsss.data;
    }
    publishereditall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const publishereditallsss = await Axios.post('publisheredit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return publishereditallsss.data;
    }
    articleeditall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const articleeditallsss = await Axios.post('articleedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return articleeditallsss.data;
    }
    poemeditall = async (books,theUserid) => {
        const poemeditallsss = await Axios.post('poemedit.php',{
            title:books.title,
            abouts:books.abouts,
            theUserid:theUserid,
        });
        return poemeditallsss.data;
    }
    trailerpaysmsg = async (payids) => {
        const trailerpaysmsgqqq = await Axios.post('trailerpaysmsg.php',{
            payids:payids,
        });
        return trailerpaysmsgqqq.data;
    }
    trailerpaysmsgpaypal = async (payids) => {
        const trailerpaysmsgpaypalqqq = await Axios.post('trailerpaysmsgpaypal.php',{
            payids:payids,
        });
        return trailerpaysmsgpaypalqqq.data;
    }
    publisherpaysmsg = async (payids) => {
        const publisherpaysmsgqqq = await Axios.post('publisherpaysmsg.php',{
            payids:payids,
        });
        return publisherpaysmsgqqq.data;
    } 
    publisherpaysmsgpaypal = async (payids) => {
        const publisherpaysmsgpaypalqqq = await Axios.post('publisherpaysmsgpaypal.php',{
            payids:payids,
        });
        return publisherpaysmsgpaypalqqq.data;
    }
    bookpayreceipt = async (payids) => {
        const bookpayreceiptsss = await Axios.post('payreceipts.php',{
            payids:payids,
        });
        return bookpayreceiptsss.data;
    }
    interviewaddall = async (books,purchase,purchagelength,theUserid,types) => {
        const formData = new FormData();
        formData.append('videos',books.videos);
        formData.append('title',books.title);
        formData.append('avatar',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('theUserid',theUserid);
        formData.append('types',types);
        const interviewaddallqqq = await Axios.post('interviewsadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return interviewaddallqqq.data;
    }
    interviewaddtwoall = async (books,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);
        formData.append('qone',books.qone);
        formData.append('qtwo',books.qtwo);
        formData.append('qthree',books.qthree);
        formData.append('qfour',books.qfour);
        formData.append('qfive',books.qfive);
        formData.append('qsix',books.qsix);
        formData.append('qseven',books.qseven);
        formData.append('qeight',books.qeight);
        formData.append('qnine',books.qnine);
        formData.append('qten',books.qten);
        formData.append('qellavan',books.qellavan);
        formData.append('qtwelve',books.qtwelve);
        formData.append('qthirteen',books.qthirteen);
        formData.append('qfourteen',books.qfourteen);
        formData.append('qfiveteen',books.qfiveteen);
        formData.append('qsixteen',books.qsixteen);
        formData.append('qseventeen',books.qseventeen);
        formData.append('qeighteen',books.qeighteen);
        formData.append('qnineteen',books.qnineteen);
        formData.append('qtwinty',books.qtwinty);
        formData.append('qtwintyone',books.qtwintyone);
        formData.append('qtwintytwo',books.qtwintytwo);

        formData.append('theUserid',theUserid);
        const interviewaddtwoallqqq = await Axios.post('interviewsaddtwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return interviewaddtwoallqqq.data;
    }
    userinterviewsall = async (ids) => {
        const userinterviewsallsss = await Axios.post('userinterviews.php',{
            userids:ids,
        });
        return userinterviewsallsss.data;
    }
    interviewsgetidall = async (ids) => {
        const interviewsgetidallqqq = await Axios.post('interviewsgetids.php',{
            ids:ids,
        });
        return interviewsgetidallqqq.data;
    }
    intervieweditall = async (books,purchase,purchagelength,bookids,theUserid) => {
        const formData = new FormData();
        formData.append('videos',books.videos);
        formData.append('avatar',books.singleimg);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('bookids',bookids);
        formData.append('theUserid',theUserid);
        formData.append('title',books.title);
        formData.append('avatarimgactive',books.singleimgactive);
        const intervieweditallqqq = await Axios.post('interviewsedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return intervieweditallqqq.data;
    }
    intervieweditalltwo = async (books,bookids,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singleimg);
        formData.append('avatarimgactive',books.singleimgactive);

        formData.append('qone',books.qone);
        formData.append('qtwo',books.qtwo);
        formData.append('qthree',books.qthree);
        formData.append('qfour',books.qfour);
        formData.append('qfive',books.qfive);
        formData.append('qsix',books.qsix);
        formData.append('qseven',books.qseven);
        formData.append('qeight',books.qeight);
        formData.append('qnine',books.qnine);
        formData.append('qten',books.qten);
        formData.append('qellavan',books.qellavan);
        formData.append('qtwelve',books.qtwelve);
        formData.append('qthirteen',books.qthirteen);
        formData.append('qfourteen',books.qfourteen);
        formData.append('qfiveteen',books.qfiveteen);
        formData.append('qsixteen',books.qsixteen);
        formData.append('qseventeen',books.qseventeen);
        formData.append('qeighteen',books.qeighteen);
        formData.append('qnineteen',books.qnineteen);
        formData.append('qtwinty',books.qtwinty);
        formData.append('qtwintyone',books.qtwintyone);
        formData.append('qtwintytwo',books.qtwintytwo);

        formData.append('bookids',bookids);
        formData.append('theUserid',theUserid);
        const intervieweditalltwoqqq = await Axios.post('interviewsedittwo.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return intervieweditalltwoqqq.data;
    }
    emailverifysall = async (userInfo,ids) => {
        const emailverifysallqqq = await Axios.post('login/emailverifys.php',{
            sms:userInfo.sms,
            ids:ids,
        });
        return emailverifysallqqq.data;
    }
    categoryshomeall = async () => {
        const categoryshomeall = await Axios.get('home/categorysallhome.php');
        return categoryshomeall.data;
    }
    interviewshomeall = async () => {
        const interviewshomeallsss = await Axios.get('home/interviewshome.php');
        return interviewshomeallsss.data;
    }
    bookshomeall = async () => {
        const bookshomeallsss = await Axios.get('home/bookshome.php');
        return bookshomeallsss.data;
    } 
    booksFeaturedall = async () => {
        const booksFeaturedallsss = await Axios.get('home/booksFeaturedall.php');
        return booksFeaturedallsss.data;
    }
    booksofdayall = async () => {
        const booksofdayallsss = await Axios.get('home/booksofday.php');
        return booksofdayallsss.data;
    }
    bookstrailerhomeall = async () => {
        const bookstrailerhomeallsss = await Axios.get('home/bookstrailerhome.php');
        return bookstrailerhomeallsss.data;
    }
    articlehomeall = async () => {
        const articlehomeallsss = await Axios.get('home/articlehome.php');
        return articlehomeallsss.data;
    }
    poemhomeall = async () => {
        const poemhomeallsss = await Axios.get('home/poemhome.php');
        return poemhomeallsss.data;
    }
    publisherhomeall = async () => {
        const publisherhomeallsss = await Axios.get('home/publisherhome.php');
        return publisherhomeallsss.data;
    }
    qavideohomeall = async () => {
        const qavideohomeallsss = await Axios.get('home/qavideohome.php');
        return qavideohomeallsss.data;
    }
    AllArticlelist  = async (pageNumber) => {
        const AllArticlelistsss = await Axios.get(`page/allarticlelist.php?page=${pageNumber}`);
        return AllArticlelistsss.data;
    }
    AllPeomlist  = async (pageNumber) => {
        const AllPeomlistsss = await Axios.get(`page/allpoemlist.php?page=${pageNumber}`);
        return AllPeomlistsss.data;
    }
    Allbooktrailerlist  = async (pageNumber) => {
        const Allbooktrailerlistsss = await Axios.get(`page/allbooktrailerlist.php?page=${pageNumber}`);
        return Allbooktrailerlistsss.data;
    }
    Allinterviewlist  = async (pageNumber) => {
        const Allinterviewlistsss = await Axios.get(`page/allinterviewlist.php?page=${pageNumber}`);
        return Allinterviewlistsss.data;
    }
    Allaudiobookslist  = async (pageNumber) => {
        const Allaudiobookslistsss = await Axios.get(`page/allaudiobookslist.php?page=${pageNumber}`);
        return Allaudiobookslistsss.data;
    }
    Allbookslist  = async (pageNumber) => {
        const Allbookslistsss = await Axios.get(`page/allbookslist.php?page=${pageNumber}`);
        return Allbookslistsss.data;
    }  
    Allbookslistcover  = async (pageNumber) => {
        const Allbookslistcoversss = await Axios.get(`page/allbookslistcover.php?page=${pageNumber}`);
        return Allbookslistcoversss.data;
    } 
    categorylistpreviewall  = async (pageNumber,pagetitle) => {
        const categorylistpreviewallqqq = await Axios.get(`page/categorylistall.php?page=${pageNumber}&pagetitle=${pagetitle}`);
        return categorylistpreviewallqqq.data;
    } 
    Allpublisherlist  = async (pageNumber) => {
        const Allpublisherlistsss = await Axios.get(`page/allpublisherlist.php?page=${pageNumber}`);
        return Allpublisherlistsss.data;
    }    
    bookpreviewall = async (ids) => {
        const bookpreviewallsss = await Axios.post('page/bookpreviewall.php',{
            ids:ids,
        });
        return bookpreviewallsss.data;
    }
    userlistall = async (ids) => {
        const userlistallqqq = await Axios.post('page/authorprofile.php',{
            userids:ids,
        });
        return userlistallqqq.data;
    }
    userpreviewall = async (ids) => {
        const userpreviewall = await Axios.post('page/userpreviewall.php',{
            ids:ids,
        });
        return userpreviewall.data;
    }
    publisherpreviewall = async (ids) => {
        const publisherpreviewallsss = await Axios.post('page/publisherpreviewall.php',{
            ids:ids,
        });
        return publisherpreviewallsss.data;
    }
    articlepreviewall = async (ids) => {
        const articlepreviewallsss = await Axios.post('page/articlepreviewall.php',{
            ids:ids,
        });
        return articlepreviewallsss.data;
    }
    poempreviewall = async (ids) => {
        const poempreviewallsss = await Axios.post('page/poempreviewall.php',{
            ids:ids,
        });
        return poempreviewallsss.data;
    }
    
    interviewpreviewall = async (ids) => {
        const interviewpreviewallsss = await Axios.post('page/interviewpreviewall.php',{
            ids:ids,
        });
        return interviewpreviewallsss.data;
    }
    Authorslist  = async (pageNumber) => {
        const Authorslistsss = await Axios.get(`page/authorsall.php?page=${pageNumber}`);
        return Authorslistsss.data;
    }
    AuthorsPhotoProfile  = async (pageNumber) => {
        const AuthorsPhotoProfilesss = await Axios.get(`page/authorsphotoprofile.php?page=${pageNumber}`);
        return AuthorsPhotoProfilesss.data;
    }
    AuthorsPhotolist  = async (pageNumber) => {
        const AuthorsPhotolistsss = await Axios.get(`page/authorsphotoall.php?page=${pageNumber}`);
        return AuthorsPhotolistsss.data;
    }
    addnewsletters = async (user) => {
        const addnewsletterssss = await Axios.post('addnewsletters.php',{
            email:user.email,
        });
        return addnewsletterssss.data;
    }
    priceaddall = async (price,plan,count,userids) => {
        const priceaddallsss = await Axios.post('priceaddall.php',{
            price:price,
            plan:plan,
            counts:count,
            userids:userids,
        });
        return priceaddallsss.data;
    }
    userpriceall = async (userids) => {
        const userpriceallsss = await Axios.post('userpriceall.php',{
            userids:userids,
        });
        return userpriceallsss.data;
    }
    useraddcheckall = async (userids) => {
        const useraddcheckallsss = await Axios.post('useraddcheckall.php',{
            userids:userids,
        });
        return useraddcheckallsss.data;
    }
    voteaddall = async (bookids,userids) => {
        const voteaddallsss = await Axios.post('page/voteaddall.php',{
            bookids:bookids,
            userids:userids,
        });
        return voteaddallsss.data;
    }
    usercheckcoversall = async (userids) => {
        const usercheckcoversallsss = await Axios.post('page/usercheckcoversall.php',{
            userids:userids,
        });
        return usercheckcoversallsss.data;
    }
    updatepayall = async (bookids,userids) => {
        const updatepayallsss = await Axios.post('updatepayall.php',{
            bookids:bookids,
            userids:userids,
        });
        return updatepayallsss.data;
    }

    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            userdetailsall:this.userdetailsall,
            editprofileall:this.editprofileall,
            Booksaddall:this.Booksaddall,
            Booksaddalltwo:this.Booksaddalltwo,
            userbooksall:this.userbooksall,
            bookgetidall:this.bookgetidall,
            editbookall:this.editbookall,
            AudioBooksaddall:this.AudioBooksaddall,
            AudioBooksaddalltwo:this.AudioBooksaddalltwo,
            editaudiobookall:this.editaudiobookall,
            booktrailersall:this.booktrailersall,
            Booktraileraddall:this.Booktraileraddall,
            booktrailergetidall:this.booktrailergetidall,
            Booktrailereditall:this.Booktrailereditall,
            bookpaysmsg:this.bookpaysmsg,
            bookpaypalmsg:this.bookpaypalmsg,
            userpublishersall:this.userpublishersall,
            userpoemall:this.userpoemall,
            userarticleall:this.userarticleall,
            publisheraddall:this.publisheraddall,
            articleaddall:this.articleaddall,
            poemaddall:this.poemaddall,
            pubsgetidall:this.pubsgetidall,
            poemgetidall:this.poemgetidall,
            articlegetidall:this.articlegetidall,
            publishereditall:this.publishereditall,
            articleeditall:this.articleeditall,
            poemeditall:this.poemeditall,
            trailerpaysmsg:this.trailerpaysmsg,
            publisherpaysmsg:this.publisherpaysmsg,
            bookpayreceipt:this.bookpayreceipt,
            interviewaddall:this.interviewaddall,
            userinterviewsall:this.userinterviewsall,
            interviewsgetidall:this.interviewsgetidall,
            intervieweditall:this.intervieweditall,
            intervieweditalltwo:this.intervieweditalltwo,
            emailverifysall:this.emailverifysall,
            categoryshomeall:this.categoryshomeall,
            interviewshomeall:this.interviewshomeall,
            bookshomeall:this.bookshomeall,
            bookstrailerhomeall:this.bookstrailerhomeall,
            articlehomeall:this.articlehomeall,
            poemhomeall:this.poemhomeall,
            publisherhomeall:this.publisherhomeall,
            qavideohomeall:this.qavideohomeall,
            AllArticlelist:this.AllArticlelist,
            AllPeomlist:this.AllPeomlist,
            Allbooktrailerlist:this.Allbooktrailerlist,
            Allinterviewlist:this.Allinterviewlist,
            Allaudiobookslist:this.Allaudiobookslist,
            Allbookslist:this.Allbookslist,
            Allbookslistcover:this.Allbookslistcover,
            Allpublisherlist:this.Allpublisherlist,
            bookpreviewall:this.bookpreviewall,
            userlistall:this.userlistall,
            userpreviewall:this.userpreviewall,
            publisherpreviewall:this.publisherpreviewall,
            articlepreviewall:this.articlepreviewall,
            poempreviewall:this.poempreviewall,
            interviewaddtwoall:this.interviewaddtwoall,
            interviewpreviewall:this.interviewpreviewall,
            Authorslist:this.Authorslist,
            trailerpaysmsgpaypal:this.trailerpaysmsgpaypal,
            publisherpaysmsgpaypal:this.publisherpaysmsgpaypal,
            addnewsletters:this.addnewsletters,
            priceaddall:this.priceaddall,
            userpriceall:this.userpriceall,
            useraddcheckall:this.useraddcheckall,
            categorylistpreviewall:this.categorylistpreviewall,
            booksofdayall:this.booksofdayall,
            voteaddall:this.voteaddall,
            usercheckcoversall:this.usercheckcoversall,
            AuthorsPhotoProfile:this.AuthorsPhotoProfile,
            AuthorsPhotolist:this.AuthorsPhotolist,
            booksFeaturedall:this.booksFeaturedall,
            updatepayall:this.updatepayall,


        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;
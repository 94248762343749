import React, { Suspense, lazy } from 'react';
import {Routes, Route, Link} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';

const Home = lazy(() => import('./page/Home'));
const LoginSection = lazy(() => import('./page/login/LoginSection'));
const ChangePassword = lazy(() => import('./page/login/ChangePassword'));
const Logout = lazy(() => import('./page/login/Logout'));
const MyAccount = lazy(() => import('./page/account/Index'));
const Article = lazy(() => import('./page/Article'));
const Poem = lazy(() => import('./page/Poem'));
const Publisher = lazy(() => import('./page/Publisher'));
const Books = lazy(() => import('./page/Books'));
const AudioBooks = lazy(() => import('./page/AudioBooks'));
const BooksTrailers = lazy(() => import('./page/BooksTrailers'));
const Interviews = lazy(() => import('./page/Interviews'));
const InterviewsPreview = lazy(() => import('./page/InterviewsPreview'));
const BookPreview = lazy(() => import('./page/BookPreview'));
const AuthorPage = lazy(() => import('./page/AuthorPage'));
const PublisherPreview = lazy(() => import('./page/PublisherPreview'));
const ArticlePreview = lazy(() => import('./page/ArticlePreview'));
const PoemPreview = lazy(() => import('./page/PoemPreview'));
const Demo = lazy(() => import('./page/Demo'));
const Search = lazy(() => import('./page/search/Search'));
const Authors = lazy(() => import('./page/Authors'));
const Contactus = lazy(() => import('./page/Contactus'));
const Categeorylist = lazy(() => import('./page/Categeorylist'));
const BookCover = lazy(() => import('./page/BookCover'));




const NotFound = lazy(() => import("./page/NotFound"));

function App() {
  
  return (<>   
    <>
      <Suspense fallback={<div className="divLoaderwww"> Loading... </div>}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/LoginSection" element={<LoginSection />} />
          <Route path="/ChangePassword/:passkey" element={<ChangePassword />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="/Article" element={<Article />} />
          <Route path="/Poem" element={<Poem />} />
          <Route path="/Publisher" element={<Publisher />} />
          <Route path="/Books" element={<Books />} />
          <Route path="/AudioBooks" element={<AudioBooks />} />
          <Route path="/BooksTrailers" element={<BooksTrailers />} />
          <Route path="/Interviews" element={<Interviews />} />
          <Route path="/BookPreview/:Addpreview/:title" element={<BookPreview />} />
          <Route path="/PublisherPreview/:Addpreview/:title" element={<PublisherPreview />} />
          <Route path="/ArticlePreview/:Addpreview/:title" element={<ArticlePreview />} />
          <Route path="/InterviewsPreview/:Addpreview/:title" element={<InterviewsPreview />} />
          <Route path="/PoemPreview/:Addpreview/:title" element={<PoemPreview />} />
          <Route path="/AuthorPage/:Addpreview" element={<AuthorPage />} />
          <Route path="myAccount/*" element={<MyAccount />} />
          <Route path="/Search" element={<Search />} />
          <Route path="/Authors" element={<Authors />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/Categeorylist/:Addpreview" element={<Categeorylist />} />
          <Route path="/BookCover" element={<BookCover />} />



          <Route path="/Demo" element={<Demo />} />


          <Route path="*" element={<NotFound />} />

        </Routes>
      </Suspense>
    </>
    
    </>
  );
}
export default App;
